<template>
    <div class="modal-bg" v-show="show" @mousemove="modalMove" @mouseup="cancelMove">
        <div class="modal-container">
            <div class="modal-header" @mousedown="setStartingPoint">
                <div style="margin: 0 0 0 20px;text-align: left;width:100%;">{{ title }}</div>
            </div>
            <div class="modal-main1">
            	<div class="maintitle"><div class="mainleft">案例概况</div></div>
            	<div class="maincontxt">           		
	            	<div class="mainpic mainpicnew">
	            		
	            		<div class="maininput">
	            			<div class="maiesef">序</div>
							<div class="maiesef">号：</div>
	            			<input class=" inptsty  inptwd"ty ="text"  ref="sort"id="" value="" />	
	            		</div>
	            		<div class="maininput">
	            			<div class="mainsinputele">数据名称：</div>
	            			<input class=" inptsty inptwitdti"ty ="text" name="" ref="name"id=""  value="" />						
	            		</div>
	            		<div class="maininput">
	            			<div class="mainsinputele">数据概况：</div>
	            			<input class=" inptsty inptwitdti"ty ="text" name="" ref="context"id="" value="" />						
	            		</div>
	            		<div class="maininput">	            			
	            			<div class="maiesef">频</div>
							<div class="maiesef">次：</div>
	            			<input class=" inptsty  inptwd"ty ="text" name="" ref="times"id="" value="" />						
	            		</div>
	            		
            		</div>
            	</div>          	
            </div>
            <div class="modal-footer">
                <button round @click="hideModal">取消</button>
                <button type="primary" round @click="submit">提交</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {addWeather} from "network/home";
import qs from 'qs';
export default {
    name: 'modal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        addtype:{
        	type:String,
        	default: ''
        }
    },
    data() {
        return {
            x: 0,
            y: 0,
            node: null,
            isCanMove: false,
            value1: new Date(), //开始时间
            imageUrl:require('@/assets/img/noimage.png'),
            ttle:["风险预警数据","指数预报数据","天气预报数据","格点天气预报数据",
            "地面观测、高空观测、雷达资料、卫星资料等天气实况数据","气候资料、气候评价、灾害评估、大型活动安全风险评估等数据",
            "空气质量实况和预报","辐射资料"],
            type:["yj","zsyb","yb","gdyb","tqsk","fxpg","kqskyb","fs"],
        }
    },
    mounted() {
        this.node = document.querySelector('.modal-container')
    },
    methods: {
        hideModal() {      	
            this.$emit('hideModal');
            this.cleatForm1();
        },
		//验证序号
		checkNumber(theObj) {
		  var reg = /^[0-9]+.?[0-9]*$/;
		  if (reg.test(theObj)) {
		    return true;
		  }
		  return false;
		},
		
        submit() {
        	var s1=this.$refs.sort.value;
        	var s2=this.$refs.name.value;
        	var s3=this.$refs.context.value;
        	var s4=this.$refs.times.value;
        	var ttle=this.ttle[this._props.addtype];
        	var tp=this.type[this._props.addtype];
        	var to=localStorage.getItem('Authorization');
            if(s1.length==0){
				this.$message.warning('序号不能为空')	
            }else if (!this.checkNumber(s1)) {
				  this.$message.warning('序号只能为数字');
				  return false;
			}else if(s2.trim().length==0){
				this.$message.warning('数据名称不能为空')	
            }else if(s3.trim().length==0){
				this.$message.warning('数据概况不能为空')	
            }else{
            	addWeather(s3,s2,s4,s1,ttle,tp).then( result =>{
			   	if(result.describe=="操作成功！"){
				   	alert(result.describe);
				   	this.cleatForm1();
		            this.$emit('submit');
				   }else if(result.describe=="token已过期"){
			            alert("用户未登录，请先登录");
			            this.$router.push('/Login');
		           }else{
				   	alert(result.describe+"请重试");
				   }
		        }).catch(error => {
		            console.log(error);
		            this.message="请求错误，请刷新";
		        });
            }
//      	
	        
            

            
        },
        setStartingPoint(e) {
            this.x = e.clientX - this.node.offsetLeft
            this.y = e.clientY - this.node.offsetTop
            this.isCanMove = true
        },

        modalMove(e) {
            if (this.isCanMove) {
                this.node.style.left = e.clientX - this.x + 'px'
                this.node.style.top = e.clientY - this.y + 'px'
            } 
        },

        cancelMove() {
            this.isCanMove = false
        },
        cleatForm1(){
        	this.$refs.sort.value="";
        	this.$refs.name.value="";
        	this.$refs.context.value="";
        	this.$refs.times.value="";
        }
    }
}
</script>
<style >
.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 10;
}
.modal-container {
    background: #fff;
    border-radius: 10px;
    overflow-y:auto;
    position: fixed;
    top: 50%;
    width:1000px;
    height:85%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.modal-header {
    height: 50px;
    color: #000000;
    font-size:23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
    border-bottom: 1px solid #bac0cc;
}
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 57px;
    border-top: 1px solid #ddd;
}
.modal-footer button {
    width: 80px;
    margin: 10px;
}
.maintitle{
	background: #bfddf7;
	color:#465e74;
	font-size: 18px;
	line-height: 40px;
	height: 40px;
	width: 100%;
	padding: 0.5px;
	border-radius: 8px 8px 0 0;
}
.mainleft{
	padding-left:10px;
}
.modal-main {   
    height: 250px;
    border: 1px solid darkgray;
    margin: 20px;
    border-radius: 8px;
}
.maincontxt{
	 padding: 15px ;
	 height: 100%;
}
.maincontxtall{
	 padding: 8px ;
	 height: 100%;
}
.mainpic{
	width:35%;
	height: 200px;
	float: left;
	font-size: 17px;
	font:bold;
}
.mainpicnew{
	width:100%;
	padding-top: 10px;
}
.modal-main1 {   
    height: 270px;
    border: 1px solid darkgray;
    margin: 20px;
    border-radius: 8px;
}
.mainpicdi{
	padding: 5px;	
}
.maimg{
	margin: 0 0 0 30px;
	padding: 3px;
	border: 1px solid  darkgray;
}
.maininput{
	width:100%;
	height: 50px;
}
.mainsinputele{
	float: left;
	width:100px;
	line-height: 30px;
}
.maiesef{
	float: left;
	width:50px;
	line-height: 30px;
}
.inptwitdti{
	width:800px;
}
.btblselnew{
	margin: 10px 0 0 0;
	float: left;
	width: 80px;
	background: cornflowerblue;
	color:#ffffff;
	border-radius: 5px;
	border: 0;
	height: 30px;
	line-height: 30px;
	font-size: 16px;
}

textarea{
	width:100%;
	height: 79%;
}
</style>